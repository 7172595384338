import { useStaticQuery, graphql } from "gatsby"

export const useWpUser = () => {
    const wpUser = useStaticQuery(graphql`
        query {
            # if there's more than one user this would need to be filtered to the main user
            wpUser {
                twitter: name
            }
        }
  `)

    return wpUser;
}

import React from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import "./RegulationPageListing.scss"
import RegulationPanel from "../RegulationPanel/RegulationPanel"

const RegulationPageListing = ({ data }) => {
  return (
    <section className="regulations-listing">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Regulamente Concursuri - ensys</h2>
            <hr className="divider" />
          </div>
        </div>

        <div className="row">
          {data &&
            data.map(regulation => (
              <div className="col-md-12 col-lg-12" key={uniqueId("bl_ens_")}>
                <RegulationPanel
                  key={regulation.id}
                  title={regulation.title || ""}
                  date={regulation.date}
                  link={regulation.link}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

RegulationPageListing.defaultProps = {
  data: [],
}

RegulationPageListing.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}
export default RegulationPageListing

/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes, { any } from "prop-types"
import { Helmet } from "react-helmet"
import { useSeo } from "../hooks/Seo/useSeo"
import { useWpUser } from "../hooks/Seo/useWpUser"

const SeoPage = ({
  title,
  description,
  keyword,
  image,
  canonicalLink,
  lang,
  meta,
  link,
  slug,
}) => {
  const allWpPage = useSeo();
  const wpUser = useWpUser();

  let page;
  allWpPage.nodes.forEach(element => {
    if (element.title === slug) {
      page = element
    }
  })

  const url = typeof window !== "undefined" ? window.location.href : ""

  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Ensys",
    "url": url,
  }

  const defaultTitle = title || page?.seo?.title
  const defaultMetaDescription = description || page?.seo?.metaDesc
  const defaultKeyword = keyword || page?.seo?.metaKeywords || ""
  const defaultImage =
    image ||
    page?.seo?.opengraphImage?.link ||
    "https://wp.ensys.com.ro/wp-content/uploads/2023/03/ENS-OG-IMAGE.png"
  const defaultCanonicalLink = canonicalLink || url || ""
  const defaultTwitterCard = "summary"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      script={[
        {
          type: "text/javascript",
          src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
          async: true
        },
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(schema),
        },
      ]}
      link={[
        {
          rel: `canonical`,
          href: defaultCanonicalLink,
        },
      ].concat(link)}
      meta={[
        {
          name: `title`,
          content: defaultTitle,
        },
        {
          name: `description`,
          content: defaultMetaDescription,
        },
        {
          name: `keywords`,
          content: defaultKeyword,
        },
        {
          property: `image`,
          content: defaultImage,
        },
        {
          name: `robots`,
          content:
            "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:image`,
          content: defaultImage,
        },
        {
          property: `og:description`,
          content: defaultMetaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: defaultTwitterCard,
        },
        {
          name: `twitter:image`,
          content: defaultImage,
        },
        {
          name: `twitter:creator`,
          content: wpUser?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: defaultMetaDescription,
        },
      ].concat(meta)}
    />
  )
}

SeoPage.defaultProps = {
  description: ``,
  keyword: ``,
  image: ``,
  canonicalLink: ``,
  lang: `en`,
  meta: [],
  link: [],
}

SeoPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keyword: PropTypes.string,
  image: PropTypes.any,
  canonicalLink: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  slug: any.isRequired,
}

export default SeoPage

import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useSeo = () => {
    const { i18n } = useTranslation()
    const { language } = i18n
    const { allWpPageRo, allWpPageEn } = useStaticQuery(graphql`
        query {
            allWpPageRo: allWpPage(filter: {language: {code: {eq: RO}}}) {
                nodes {
                    title
                    seo {
                        title
                        metaDesc
                        focuskw
                        metaKeywords
                        opengraphImage {
                            link
                        }
                    }
                }
            }
            allWpPageEn: allWpPage(filter: {language: {code: {eq: EN}}}) {
                nodes {
                    title
                    seo {
                        title
                        metaDesc
                        focuskw
                        metaKeywords
                        opengraphImage {
                            link
                        }
                    }
                }
            }
            # if there's more than one user this would need to be filtered to the main user
            wpUser {
                twitter: name
            }
        }
  `)

    const pages = {
        en: allWpPageEn,
        ro: allWpPageRo,
    }

    return pages?.[language] ?? allWpPageRo;
}

import React from "react"
import PropTypes from "prop-types"
import "./RegulationPanel.scss"
import EnsLink from "../EnsLink/EnsLink"

const RegulationPanel = ({ title, date, link }) => {
  return (
    <EnsLink link={{ url: link }}>
      <div className="regulation-panel">
        <h4 className="title">{title}</h4>
        {date && <span className="article-date">{date}</span>}
      </div>
    </EnsLink>
  )
}

RegulationPanel.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default RegulationPanel
